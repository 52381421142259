import type { ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
  StyleSheetManager,
  ThemeProvider as ThemeProviderStyledComponent,
} from 'styled-components';

import {
  mediaQueryContext,
  themeColorContext,
  useContextMediaQuery,
  useContextThemeColor,
} from '@core/context';
import {
  DeviceSize,
  EColor,
  ESize,
  ThemeColorType,
  ThemeDevice,
  ThemeDeviceReturn,
  ThemeMediaQuery,
} from '@core/type';
import {
  MediaQueryHook,
  MediaQueryState,
  ThemeColorHook,
  ThemeColorState,
} from '@core/type/context';

import { storeHook } from '../helpers';
import { useMediaQuery, useThemeColor } from '../hook';

export type ThemeProps = {
  themeMediaQueries: ThemeMediaQuery;
  themeXL: ThemeDeviceReturn;
  themeLG: ThemeDeviceReturn;
  themeMD: ThemeDeviceReturn;
  themeSM: ThemeDeviceReturn;
  themeXS: ThemeDeviceReturn;
  themeColorList: ThemeColorType;
  deviceDetected?: DeviceSize;
};

type ThemeChildrenProps = {
  themeXL: ThemeDeviceReturn;
  themeLG: ThemeDeviceReturn;
  themeMD: ThemeDeviceReturn;
  themeSM: ThemeDeviceReturn;
  themeXS: ThemeDeviceReturn;
  themeColorList: ThemeColorType;
  deviceDetected?: DeviceSize;
};

const ThemeChildren = ({
  children,
  themeXL,
  themeLG,
  themeMD,
  themeSM,
  themeXS,
  themeColorList,
}: { children?: ReactNode } & ThemeChildrenProps) => {
  const { mediaQuery, detectRange } = useContextMediaQuery();
  const { themePalette } = useContextThemeColor();

  const getTheme = useCallback((): ThemeDevice => {
    switch (mediaQuery) {
      case ESize.XL:
        return themeXL();
      case ESize.LG:
        return themeLG();
      case ESize.MD:
        return themeMD();
      case ESize.SM:
        return themeSM();
      case ESize.XS:
        return themeXS();
      default:
        return themeXL();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaQuery]);

  useEffect(() => {
    function handleResize() {
      detectRange(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    if (detectRange(window.innerWidth) !== mediaQuery) {
      handleResize();
    }
    return () => {
      return window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProviderStyledComponent
      theme={{
        ...getTheme(),
        palette: themePalette || themeColorList ? themeColorList[EColor.MAIN] : null,
      }}
    >
      {children}
    </ThemeProviderStyledComponent>
  );
};

export const ProviderTheme = ({
  children,
  themeMediaQueries,
  themeXL,
  themeLG,
  themeMD,
  themeSM,
  themeXS,
  themeColorList,
  deviceDetected,
}: { children?: ReactNode } & ThemeProps) => {
  const [isDisableCSSOMInjection, setIsDisableCSSOMInjection] = useState<boolean>(false);
  const hookMediaQuery = storeHook<MediaQueryState, MediaQueryHook>(
    {
      mediaQuery: deviceDetected,
      themeMediaQueries,
      isPhone: deviceDetected === ESize.XS,
      isTablet: deviceDetected === ESize.MD,
      isMobile: deviceDetected !== ESize.XL,
      isDesktop: deviceDetected === ESize.XL,
    },
    useMediaQuery,
  );
  const hookThemeColor = storeHook<ThemeColorState, ThemeColorHook>(
    {
      isDark: false,
      themePalette: themeColorList ? themeColorList[EColor.MAIN] : null,
      listThemeColor: themeColorList,
    },
    useThemeColor,
  );

  useEffect(() => {
    if (
      window?.location?.host === 'app.crisp.chat' ||
      window?.parent?.location?.host === 'app.crisp.chat' ||
      window?.parent?.document?.location?.host === 'app.crisp.chat' ||
      document?.location?.host === 'app.crisp.chat' ||
      window?.self?.host === 'app.crisp.chat' ||
      window?.top?.host === 'app.crisp.chat'
    ) {
      setIsDisableCSSOMInjection(true);
    }
  }, [deviceDetected]);

  return (
    <StyleSheetManager disableCSSOMInjection={isDisableCSSOMInjection}>
      <mediaQueryContext.Provider value={hookMediaQuery}>
        <themeColorContext.Provider value={hookThemeColor}>
          <ThemeChildren
            {...{
              themeXL,
              themeLG,
              themeMD,
              themeSM,
              themeXS,
              themeColorList,
              deviceDetected,
            }}
          >
            {children}
          </ThemeChildren>
        </themeColorContext.Provider>
      </mediaQueryContext.Provider>
    </StyleSheetManager>
  );
};
